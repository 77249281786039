<template>
  <!-- <!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <link rel="icon" href="/favicon.ico" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Vite App</title>
  </head>
  <body>
    <div id="app"></div>
    <script type="module" src="/src/main.ts"></script> 
  </body>
</html> -->


  <!DOCTYPE html>
  <html>

  <head>
    <title>Credin Shiksha</title>
    <!-- <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"> -->
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <!-- <link rel="preconnect" href="https://fonts.googleapis.com"> -->
    <!-- <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin> -->
    <!-- <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap" rel="stylesheet"> -->
    <!-- <script src="https://unpkg.com/vue@3"></script> -->

  </head>

  <body>
    <div class="nav" :class="{ containermain: viewIcon }">
      <div class="part" id="nav-part">
        <a href="#" class="navbar">
          <img class="img" id="myTopnavTwo" src="../assets/img/Credinlogo.svg" alt="logo.svg" height="70" width="140">
        </a>
        <div class="navnav" id="myTopnav">
          <p class="menu" id="current"><span id="current-one" class="navbarsmap">Ho</span>me</p>
          <div class="dropdown" id="current">
            <button class="dropbtn">Products
              <i class="fa fa-caret-down"></i>
            </button>
            <div class="dropdown-content">
              <a href="Pratham.html">Pratham</a>
              <a href="#">Link 2</a>
              <a href="#">Link 3</a>
            </div>
          </div>
          <p class="menu" @click="$router.push('/aboutus')"><span class="navbarsmap">About</span> Us</p>
          <p class="menu" @click="$router.push('/process')"><span class="navbarsmap">How </span> it Works</p>
          <p class="menu" @click="$router.push('/faq')"><span class="navbarsmap">FA</span>Q</p>
          <p class="menu" @click="$router.push('/contactus')"><span class="navbarsmap">Contact</span> Us</p>
          <button @click="goToWebsite" class="btn">Log In</button>
          <!-- <img @click="displayHeader" src="../assets/menu-hor.svg" alt="menu-bar.svg"> -->


          <!-- <a href="#" class="menu"><span class="navbarsmap">Ho</span>me</a>
                        <div class="dropdown">
                            <button class="dropbtn">Products
                                <i class="fa fa-caret-down"></i>
                            </button>
                            <div class="dropdown-content">
                                <a href="Pratham.html">Pratham</a>
                                <a href="#">Link 2</a>
                                <a href="#">Link 3</a>
                            </div>
                        </div>
                        <a href="#" class="menu"><span class="navbarsmap">About</span> Us</a>
                        <a href="#" class="menu"><span class="navbarsmap">How </span> it Works</a>
                        <a href="#" class="menu"><span class="navbarsmap">FA</span>Q</a>
                        <a href="#footer" class="menu"><span class="navbarsmap">Contact</span> Us</a>
                        <a href="#"><button class="btn"><span class="navbarsmap">Log In</span></button></a> -->
        </div>
      </div>
    </div>

    
    <div class="box">
      <h1 class="hs">Solving Real Problem With Real Needs</h1>
      <div class="row">
        <div class="colunm1">
          <p class="let">We are leading india's growth story with true</p>
          <p class="let"> visionaries and enabling business</p>
          <ui style="margin: 15px 0px 15px 0px;">
            <dl class="box-dl"><img src="../assets/img/Check.svg"><b class="cl"> 75+ Crores</b> Loans disbursed</dl>
            <dl class="box-dl"><img src="../assets/img/Check.svg"><b class="cl"> 7500+</b> Live impacted</dl>
            <dl class="box-dl"><img src="../assets/img/Check.svg"><b class="cl"> 80+ Villages</b>Coverd</dl>
            <dl class="box-dl"><img src="../assets/img/Check.svg"><b class="cl"> 12+ Branches</b> Of Network</dl>
            <dl class="box-dl"><img src="../assets/img/Check.svg"><b class="cl"> 11K+ Happy</b> Customers</dl>
          </ui>
          <div>
            <button class="btn1">Apply Now</button>
            <button class="btn1">Check Loan Status</button>
          </div>
        </div>
        <div>
          <img class="himg" src="../assets/img/14.svg" alt="image" />
        </div>
      </div>
    </div>

    <div class="service">
      <div class="container">
        <div class="row">
          <div class="service-item" id="in">
            <div class="service-icon">
              <img src="Mission.svg">
            </div>
            <h3>Example</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua
            </p>
          </div>
          <div class="service-item" id="in1">
            <div class="service-icon">
              <img src="Vision.svg">
            </div>
            <h3>Example</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua
            </p>
          </div>
        </div>
      </div>
    </div>


    <div class="serve-box">
      <div>
        <div class="row">
          <div class="item" id="box-item">
            <!-- <div class="service-icon">
                      <img src="Mission.svg">
                  </div> -->
            <h3>Example</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua
            </p>
          </div>
          <div class="item" id="box-item">
            <!-- <div class="service-icon">
                      <img src="Vision.svg">
                  </div> -->
            <h3>Example</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua
            </p>
          </div>
        </div>
      </div>
    </div>


    <div class="cim">
      <div class="container">
        <h1>Credin in Media</h1>
        <div class="row">
          <div class="cim-item">
            <div class="cim-img"><img class="cim-img1" src="../assets/img/Forbes.svg" alt="forbes.svg"><img
                class="cim-media-img" src="../assets/img/img_134603.png" alt="credin.svg"></div>
            <div class="cim-three"><img src="img/watch.svg" alt="credin.svg">
              <p class="cim-p1">03 June, 2020</p>
            </div>
            <p class="cim-p3"><b>Credin- The need of the hour fintech platform</b></p>
            <p class="cim-p2">The happy faces you see here are of
              Birju and Rupesh, Founders, Credin who didn't take a single day off even during the
              lockdown.</p>
            <div class="cim-div">
              <p>Learn More <i class="fa fa-angle-right"></i></p>
            </div>
          </div>
          <div class="cim-item">
            <div class="cim-img">
              <img class="cim-img1" src="img/TOI.svg" alt="forbes.svg">
              <img class="cim-media-img" src="img/business-man-dollar.png" alt="credin.svg">
            </div>
            <div class="cim-three"><img src="img/watch.svg" alt="credin.svg">
              <p class="cim-p1">14 May, 2021</p>
            </div>
            <p class="cim-p3"><b>40% of parents finding it tough to pay fees</b></p>
            <p class="cim-p2">The survet comes ahead of the Gujarat high court's verdict on fixing school
              fees for the current academic year. It was carried out by a leading...</p>
            <div class="cim-div">
              <p>Learn More <i class="fa fa-angle-right"></i></p>
            </div>
          </div>
          <div class="cim-item">
            <div class="cim-img"><img class="cim-img1" src="img/ani.svg" alt="forbes.svg">
              <img class="cim-media-img" src="img/cute-girl-with-father.png" alt="credin.svg">
            </div>
            <div class="cim-three"><img src="img/watch.svg" alt="credin.svg">
              <p class="cim-p1">07 June, 2021</p>
            </div>
            <p class="cim-p3"><b>Credin Shiksha for parents to pay fee in installmets</b></p>
            <p class="cim-p2">About 40% of parents face difficulties paying school fees post lockdown this
              year. As the businesses and jobs have
              hit hard, the parent community is foc...</p>
            <div class="cim-div">
              <p>Learn More <i class="fa fa-angle-right"></i></p>
            </div>
          </div>
        </div>
      </div>
    </div>



    <div class="bg">
      <div class="footer" id="footer">
        <div class="row">
          <div class="row2">
            <div class="sub">
              <img class="img" src="img/Credin logo White.svg" alt="credin">
              <p> +91 525255252</p>
              <p><a class="foota" href="help@credin.in">help@credin.in</a></p>
              <p>3-4,Third Floor, Riyaplex,</p>
              <p>Akota BPC Road,</p>
              <p>Vadodara.</p>
            </div>

            <div class="sub">
              <h3 class="subh">Explore</h3>
              <dl>About us</dl>
              <dl>Our Team</dl>
              <dl>Services</dl>
              <dl>Process</dl>
              <dl>Contect Us</dl>
              <dl>FAQs</dl>
              <dl>Terms of service</dl>
            </div>
          </div>
          <div class="row2">
            <div class="sub">
              <h3 class="subh">Resources</h3>
              <dl>Career</dl>
              <dl>FAQs</dl>
              <dl>NBFC Partners</dl>
              <dl>Privacy policy</dl>
              <dl>Terms of service</dl>
            </div>

            <div class="sub">
              <h3 class="subh">Connect</h3>
              <dl>Twitter</dl>
              <dl>Facebook</dl>
              <dl>Instagram</dl>
              <dl>Linkedin</dl>
            </div>
          </div>
        </div>
        <div class="copytop">
          <div class="copyright">
            All right reseved &copy; Upkram Technologies pvt.Ltd. @{{ new Date().getFullYear() }}.
          </div>
        </div>
      </div>
    </div>
  </body>

  <!--  -->

  </html>

</template>

    <script>
export default {
  data() {
    return {
      popUp: false,
      popUpOne: false,
      popUpTwo: false,
      popUpThree: false,
      popUpFour: false,
      popUpFive: false,
      slideIndex: 0,
      viewIcon: false,
    }
  },

  methods: {
    displayHeader() {
      this.viewIcon = !this.viewIcon
      console.log(this.viewIcon)
      var x = document.getElementById("myTopnav");
      console.log(x)
      if (x.className === "navnav") {
        x.className += " responsive";
      } else {
        x.className = "navnav";
      }
      var y = document.getElementById("nav-part");
      if (y.className === "part") {
        y.className += "responsive-one";
      } else {
        y.className = "part";
      }
      var z = document.getElementById("myTopnavTwo");
      if (z.className === "img") {
        z.className += " responsive-two";
      } else {
        z.className = "img";
      }
    },
    goToWebsite() {
      window.location.href = 'https://borrower.credin.in/';
    }
  }
}


        // window.onscroll = function () { myFunction() };

        // var header = document.getElementById("nav-part");
        // var sticky = header.offsetTop;

        // function myFunction() {
        //     if (window.pageYOffset > sticky) {
        //         header.classList.add("sticky");
        //     } else {
        //         header.classList.remove("sticky");
        //     }
        // }
</script>

    <style scoped>
    * {
      font-family: 'Roboto', sans-serif;
    }
    
    body {
      color: black;
      /* font-family: 'Poppins', sans-serif; */
      width: auto;
      display: grid;
      overflow-x: hidden;
    }
    
    .bg1 {
      background-color: #ffffff;
    }
    
    h1,
    h2,
    h3 {
      text-align: center;
    }
    
    .row {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    
    .row2 {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    
    
    .colunm {
      display: flex;
      flex-direction: column;
    }
    
    @media(max-width:551px) {
     
      .navbar {
        margin-right: 50px;
        color: #000000;
        text-decoration: none;
        /* padding: 20px; */
      }
    
      .img {
        width: 100px;
        height: 75px;
        margin-right: 50px;
      }
    
      .navnav {
        float: right;
        display: flex;
        margin-left: 50px;
      }
    
      .btn {
        margin-left: 40px;
        margin-right: 20px;
        margin-top: 5px;
        padding: 10px 25px 10px 25px;
        background-color: #0059b3;
        color: #ffffff;
        font-size: 18px;
        border: none;
        border-radius: 4px;
        font-weight: 600;
      }
    
      .box {
        border: 2px solid #0059b3;
        margin-left: 60px;
        margin-right: 60px;
        border-radius: 7px;
        height: auto;
    
      }
    
      .box-dl {
        align-items: center;
        display: flex;
        font-size: 20px;
        margin-left: 50px;
      }
    
      .himg {
        margin: 0px;
        justify-content: center;
        width: 250px;
        height: 300px;
    
      }
    
      .let {
        font-size: 20px;
        color: lightslategrey;
        text-align: center;
        margin: 5px;
      }
    
      .colunm {
        display: flex;
        flex-direction: row;
      }
    
      .colunm1 {
        display: flex;
        flex-direction: column;
        margin: 10px;
      }
    
      .row {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: center;
      }
    
      #in,
      #in1,
      #box-item {
        margin-left: 80px;
        margin-right: 80px;
      }
    
      .cim {
        width: auto;
        padding: 45px 0 15px 0;
        margin: 20px;
        margin-left: 20px;
        margin-right: 20px;
    
      }
    
      .cim-item {
        background: #ffffff;
        width: 550px;
        margin: 20px;
        height: 750px;
        padding: 20px;
        border: 1px solid lightslategrey;
        border-radius: 5px;
        box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%),
          0 6px 20px 0 rgb(0 0 0 / 19%);
      }
    
      .cim-media-img {
        justify-content: center;
        align-items: center;
        width: 500px;
        height: 350px;
        padding: 20px;
      }
    
      .cim-p2 {
        color: gray;
        font-size: 18px;
      }
    
      .cim-p1 {
        margin-left: 1vw;
        font-size: 20px;
      }
    
      .cim-p3 {
        font-size: 22px;
        margin: 0px;
      }
    
      .chg-item {
        background: #ffffff;
        text-align: center;
        height: 150px;
        padding: 30px;
        width: 160px;
        margin: 10px;
        border-radius: 5px;
        border: 1px solid lightgrey;
        font-size: 21px;
      }
    
      .c-icon {
        margin: 0 auto 25px auto;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
      }
    
      .c-icon:hover {
        width: 55px;
        height: 55px;
      }
    
      .changep {
        text-align: center;
        color: lightslategrey;
        font-size: 20px;
      }
    
      .sub {
        margin-left: 100px;
        margin-right: 30px;
        text-align: left;
        font-size: 20px;
        width: 250px;
      }
    
      .subh {
        text-align: left;
        font-size: 27px;
      }
    
    }
    
    @media(min-width:551px) {
      .colunm1 {
        display: flex;
        flex-direction: column;
        margin: 10px;
        align-items: flex-start;
      }
    
      /* .part {
        color: #0059b3;
        position: fixed;
        top: 0;
        width: 100%;
        background-color: #ffffff;
        border-bottom: 1px solid #0059b3;
        display: flex;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: row;
                                                                                                                                       
       /* justify-content: space-between; */
    
      /* align-items: center;
        padding: 0.5%;
        z-index: 1;
        } */
    
      .navbar {
        margin-right: 80px;
        color: pink;
        text-decoration: none;
        /* padding: 20px; */
      }
    
      .img {
        width: 100px;
        height: 75px;
        margin-right: 60px;
      }
    
      .navnav {
        float: right;
        display: flex;
        margin-left: 80px;
      }
    
      .btn {
        margin-left: 80px;
        margin-right: 10px;
        margin-top: 5px;
        padding: 10px 25px 10px 25px;
        background-color: #0059b3;
        color: #ffffff;
        font-size: 18px;
        border: none;
        border-radius: 4px;
        font-weight: 600;
      }
    
      .himg {
        margin-left: 360px;
        width: 350px;
        height: 400px;
      }
    
      .box {
        border: 2px solid #0059b3;
        padding: 10px 10px 10px 10px;
        margin: 10px 90px;
        border-radius: 7px;
        height: auto;
      }
    
      .box-dl {
        align-items: center;
        display: flex;
    
      }
    
      .let {
        font-size: 14px;
        color: lightslategrey;
        text-align: center;
        margin: 5px;
      }
    
      #in {
        margin-left: 230px;
        margin-right: 60px;
      }
    
      #in1 {
        margin-right: 200px;
        margin-left: 60px;
      }
    
      #box-item {
        width: 300px;
        height: 250px;
        margin-left: 50px;
        margin-right: 50px;
      }
    
      .cim-item {
        background: #ffffff;
        width: 325px;
        margin: 15px;
        height: auto;
        padding: 25px;
        border: 1px solid lightgrey;
        border-radius: 5px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      }
    
      .cim-media-img {
        justify-content: center;
        align-items: center;
        width: 310px;
        /* margin: 10px; */
        height: 200px;
        padding: 20px 0px 20px 0px;
      }
    
      .cim-p2 {
        color: gray;
        font-size: 14px;
      }
    
      .cim-p1 {
        margin-left: 1vw;
        font-size: 16px;
      }
    
      .cim-p3 {
        font-size: 16px;
      }
    
      .remi {
        margin-left: 10px;
        /* height: 165px; */
        margin-bottom: -130px;
      }
    
      .c-icon {
        margin: 0 auto 25px auto;
        width: 60px;
        height: 60px;
        display: flex;
        justify-content: center;
    
      }
    
      .c-icon:hover {
        width: 60px;
        height: 60px;
      }
    
      .sub {
        margin-left: 75px;
        margin-right: 75px;
        text-align: left;
      }
    
      .subh {
        text-align: left;
        font-size: 25px;
      }
    }
    
    /*********** Nav Bar CSS **********/
    .part {
      /* color: #000000; */
      position: fixed;
      top: 0;
      width: 100%;
      background-color: #ffffff;
      border-bottom: 1px solid #0059b3;
      display: flex;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: row;
      /* justify-content: space-between; */
      align-items: center;
      padding: 0.5%;
      /* margin-left: 10px;
      margin-right: 10px; */
      cursor: pointer;
    }
    
  
    .menu {
      /* color: #000000; */
      float: right;
      padding: 10px 10px 8px 10px;
      margin: 2px;
      text-decoration: none;
    }
    
    .dropdown {
      float: left;
      overflow: hidden;
    }
    
    .dropdown .dropbtn {
      font-size: 16px;
      border: none;
      color: #000000;
      padding: 14px 16px;
      background-color: inherit;
      font-family: inherit;
      margin: 0;
    }
    
    /* .navbar :hover */
    .dropdown:hover .dropbtn:hover {
      background-color: #ffffff;
      color: #0059b3;
      border-radius: 4px;
    }
    
    .dropdown-content {
      display: none;
      position: absolute;
      background-color: #f9f9f9;
      min-width: 160px;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      z-index: 1;
    }
    
    .dropdown-content a {
      float: none;
      color: #000000;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
      text-align: left;
    }
    
    .dropdown-content a:hover {
      background-color: #ddd;
      color: #0059b3;
    }
    
    .dropdown:hover .dropdown-content {
      display: block;
      color: #0059b3
    }
    
    /* .btn {
      margin-left: 50px;
      margin-right: 10px;
      margin-top: 5px;
      padding: 10px 25px 10px 25px;
      background-color: #0059b3;
      color: #ffffff;
      font-size: 18px;
      border: none;
      border-radius: 4px;
      font-weight: 600;
      } */
    
    .navbarsmap:hover {
      border-bottom: 2px solid #0059b3;
    }
    
    .menu:hover {
      color: #0059b3;
    }
    
    /* .navbarsmap:focus {
    border-bottom: 2px solid #0059b3;
    }
                                                                                                                        
    .menu:active{
    color:#0059b3;
    }*/
    
    .menu:focus {
      background-color: #e6e6e6;
    }
    
    /* #current{
    color: #034ea2; 
    }
                                                                                                                        
    #current-one{
    text-decoration: underline;
    } */
    
    /****** India CSS******/
    .text {
      margin: 20px;
      /* margin-top: 100px; */
      text-align: center;
      color: #0059b3;
      font-style: bold;
    }
    
    .phead {
      margin-top: 125px;
    }
    
    .ptext {
      margin: 0px;
      text-align: center;
      font-size: 30px;
      color: #0059b3;
    }
    
    .img2 {
      align-items: center;
    }
    
    /******* box******/
    
    .hs {
      text-align: center;
    }
    
    .cl {
      color: #0059b3;
      margin: 0px 5px 0px 5px;
    }
    
    .btn1 {
      border: 2px solid #0059b3;
      background-color: #ffffff;
      color: #0059b3;
      width: 150px;
      height: 40px;
      border-radius: 5px;
      font-weight: 700;
      margin-right: 10px;
    }
    
    /****** MV CSS *********/
    .service {
      width: auto;
      padding: 45px 0 15px 0;
    
    }
    
    .service-item {
      background: #ffffff;
      text-align: center;
      margin-bottom: 30px;
      height: auto;
      padding: 30px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    
    .service-item:hover {
      box-shadow: 0 4px 8px 0 rgba(0, 89, 179, 0.3), 0 6px 20px 0 rgba(0, 89, 179, 0.3);
    }
    
    
    .service-icon {
      margin: 0 auto 25px auto;
      width: 80px;
      height: 80px;
      display: flex;
      justify-content: center;
    }
    
    .service-icon:hover {
      width: 90px;
      height: 90px;
    }
    
    .service-item h3 {
      margin-bottom: 15px;
      font-size: 20px;
      font-weight: 500;
    }
    
    .service-item p {
      margin: 0;
      font-size: 16px;
    }
    
    
    /********serve-box********/
    .serve-box {
      width: auto;
      padding: 45px 0 15px 0;
    
    }
    
    .item {
      background: #ffffff;
      text-align: center;
      margin-bottom: 30px;
      height: auto;
      padding: 30px;
      border: 1px solid #0059b3;
      border-radius: 4px;
    }
    
    .item h3 {
      margin-bottom: 15px;
      font-size: 20px;
      font-weight: 500;
    }
    
    .item p {
      margin: 0;
      font-size: 16px;
    }
    
    
    /******** CIM CSS********/
    .cim {
      width: auto;
      padding: 45px 0 15px 0;
      margin: 10px;
      margin-left: 20px;
      margin-right: 20px;
    
    }
    
    .cim-three {
      display: flex;
      flex-direction: row;
      color: lightslategray;
    }
    
    .cim-img {
      text-align: center;
    }
    
    .cim-img1 {
      width: 100%;
      /* margin-bottom: 20px; */
      /* height: 15%; */
    }
    
    .cim-div {
      display: flex;
      flex-direction: initial;
      justify-content: left;
      align-items: center;
      font-size: 18px;
      color: #0059b3;
      font-weight: 600;
    }
    
    .cim-divp {
      font-size: 20px;
      color: #0059b3;
      font-weight: 600;
    }
    
    /********* Footer CSS **********/
    
    .footer {
      font-size: 14px;
      background: #0059b3;
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
      color: #ffffff;
      padding: 40px 0 15px 0;
    }
    
    .foota {
      color: #ffffff;
      margin-bottom: 5px;
      text-decoration: none;
    }
    
    .copyright {
      font-size: 17px;
      margin-top: 10px;
      /* padding-left: 420px; */
      color: #ffffff;
      justify-content: center;
      align-items: center;
      padding: 0 28px;
    }
    
    .copytop {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      height: 30px;
      background-color: #0059b3;
      border-top: 2px solid #ffffff;
    }
    </style>
 
