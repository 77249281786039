<template>
  <div>
    <router-view></router-view>
  </div>
</template>


<style>
  @import url('https://fonts.googleapis.com/css?family=Lato');

*{
  font-family: 'Lato', sans-serif;
}

body{
  margin: 0;
  padding: 0;
}
</style>