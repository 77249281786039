import {createRouter , createWebHistory } from 'vue-router'

import HomePage from '../src/components/HomePage.vue'
import HelloWorld from '../src/components/HelloWorld.vue'
import AarambhMain from './components/AarambhMain.vue'
// import PrathamMain from './components/PrathamMain.vue'
// import ShikshaMain from './components/ShikshaMain.vue'
import FaqPage from '../src/components/FaqPage.vue'
import PrivacyPolicy from './components/PrivacyPolicy.vue'
import TermsConditions from './components/TermsConditions.vue'
import OurJourney from './components/OurJourney.vue'
import PartnersMain from './components/PartnersMain.vue'
import NBFCPageMain from './components/NBFCPageMain.vue'
import ContactusPage from './components/ContactusPage.vue'
import CareerPage from './components/CareerPage.vue'
import RealStories from './components/RealStories.vue'

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', redirect: '/home' },
        { path: '/home', component : HomePage },
        { path: '/helloworld', component : HelloWorld},
        { path: '/aarambh', component : AarambhMain},
        // { path: '/pratham', component : PrathamMain},
        // { path: '/shiksha', component : ShikshaMain},
        { path: '/faq', component : FaqPage},
        { path: '/privacy-policy', component: PrivacyPolicy},
        { path: '/terms-conditions', component :  TermsConditions},
        { path: '/ourjourney', component: OurJourney},
        { path: '/omnichannel', component: PartnersMain},
        { path: '/nbfc-partners', component:NBFCPageMain},
        { path: '/contact-us', component:ContactusPage},
        { path: '/career', component:CareerPage},
        { path: '/realstory', component:RealStories}

    ]
})

export default router;